<template>
  <div class="page installations">
    <div class="section-header">
      <h1>Installations</h1>
      <h2 class="section-tagline rot-2">Infest. Ingest. Manifest.</h2>
      <hr />
    </div>
    <!-- Start Installations Gallery -->
    <!-- <vue3-markdown-it :source="pageContent" class="installations-content" /> -->
    <div class="installations-content">
      <InstallationFeatureItem
        v-for="item in webData"
        :key="item.id"
        :title="item.title"
        :location="item.location"
        :dates="item.dates"
        :credits="item.credits"
        :blurb="item.blurb"
        :slug="item.slug"
        :heroImgs="item.heroImgs"
      />
    </div>
    <!-- End Installations Gallery -->
  </div>
</template>

<script>
// import LinkPrompter from "@/components/LinkPrompter.vue";
import InstallationFeatureItem from "@/components/InstallationFeatureItem.vue";
import installationsJSON from "@/page-contents/installations.json";
// import content from "!raw-loader!../page-contents/installations.md";

import getData from "@/services/dc-service.js";

import { computed, reactive } from "vue";
import { useHead } from "@vueuse/head";

export default {
  setup() {
    const siteData = reactive({
      title: `DEAR CLIMATE: Installations`,
      description: `INFEST. INGEST. MANIFEST.`,
    });

    useHead({
      // Can be static or computed
      title: computed(() => siteData.title),
      meta: [
        {
          name: `description`,
          content: computed(() => siteData.description),
        },
        {
          property: `og:title`,
          content: computed(() => siteData.title),
        },
        {
          property: `og:description`,
          content: `DEAR CLIMATE is a creative-research project working for a better relationship to the more-than-human world, including geo-physical forces, and others species.`,
        },
        {
          property: `og:site_name`,
          content: computed(() => siteData.title),
        },
        {
          property: `og:locale`,
          content: `en_US`,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:url`,
          content: `http://www.dearclimate.net`,
        },
        {
          property: `og:image`,
          content: `http://www.dearclimate.net/dear-climate-og-img.jpg`,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:url`,
          content: `http://www.dearclimate.net/installations`,
        },
        {
          name: `twitter:title`,
          content: computed(() => siteData.title),
        },
        {
          name: `twitter:description`,
          content: computed(() => siteData.description),
        },
        {
          name: `twitter:image`,
          content: `http://www.dearclimate.net/dear-climate-og-twitter-img.jpg`,
        },
      ],
    });
  },
  name: "Installations",
  components: {
    // LinkPrompter,
    InstallationFeatureItem,
  },
  data: function () {
    return {
      webData: [],
    };
  },
  methods: {
    fetchData() {
      console.log("Getting data from CMS.");
      getData("installations").then((response) => {
        this.processData(response.data);
      });
    },
    processData(input) {
      const fetchedData = input.data;
      for (let i = 0; i < fetchedData.length; i++) {
        let thisEntry = {};
        thisEntry.id = fetchedData[i].id;
        thisEntry.title = fetchedData[i].attributes.title;
        thisEntry.dates = fetchedData[i].attributes.dates;
        thisEntry.location = fetchedData[i].attributes.location;
        thisEntry.slug = fetchedData[i].attributes.slug;
        thisEntry.credits = fetchedData[i].attributes.credits;

        //FOR REWRITING PATHS TO IMGS UPLOADED TO STRAPI
        // const relPathWriteup = fetchedData[i].attributes.writeup;
        // const absPathWriteup = relPathWriteup.replaceAll(
        // "/uploads/",
        // "https://dear-climate-cms.herokuapp.com/uploads/"
        // );
        // thisEntry.writeup = absPathWriteup;

        // thisEntry.writeup = fetchedData[i].attributes.writeup;
        thisEntry.blurb = fetchedData[i].attributes.blurb;

        thisEntry.heroImgs = [];
        if (fetchedData[i].attributes.hero1) {
          // console.log("FETCHED HERO1", fetchedData[i].attributes.hero1);
          let heroImg = {};
          heroImg.url = ".." + fetchedData[i].attributes.hero1;
          heroImg.alt = "Alt text will go here.";
          thisEntry.heroImgs.push(heroImg);
        }
        if (fetchedData[i].attributes.hero2) {
          // console.log("FETCHED HERO2", fetchedData[i].attributes.hero2);
          let heroImg = {};
          heroImg.url = ".." + fetchedData[i].attributes.hero2;
          heroImg.alt = "Alt text will go here.";
          thisEntry.heroImgs.push(heroImg);
        }
        if (fetchedData[i].attributes.hero3) {
          // console.log("FETCHED HERO3", fetchedData[i].attributes.hero3);
          let heroImg = {};
          heroImg.url = ".." + fetchedData[i].attributes.hero3;
          heroImg.alt = "Alt text will go here.";
          thisEntry.heroImgs.push(heroImg);
        }

        // console.log("This entry: ", thisEntry);
        this.webData.push(thisEntry);
      }
      // console.log(`Data loaded: ${input.data}`);
    },
  },
  mounted() {
    this.processData(installationsJSON);
  },
};
</script>

<style lang="scss" scoped>
.installations-content {
  h1 {
    font-size: 2.8rem;
    font-family: "Torque", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial,
      sans-serif;
    font-weight: bold;
  }
  padding: 1.4rem;
}
</style>
